import React from 'react';
import './RightDescution.css';
import Icon from '../../../Icon';
import { useQuery } from 'react-query';
import { fileType } from '../../../utils/fileType';

function RightDescution({ listMessages = [] }) {
  const { data: user } = useQuery(`profile`);
  return (
    <div className='RightDescution '>
      <div className='top'>
        <h3>Profile </h3>
        <button
          className='menu-dots-vertical'
          onClick={() => {
            document
              .querySelector(' .RightDescution')
              .classList.remove('active');
            document
              .querySelector('.Descution .overlay')
              .classList.remove('active');
          }}
        >
          <Icon name='menu-dots-vertical' />
        </button>
      </div>
      <div className='body'>
        <h4>Informations</h4>

        <p className='info'>
          <Icon name='envelope' />
          {user?.email}
        </p>
        <div className='line'></div>
        <p className='info'>
          <Icon name='phone-call' />
          {user?.phone}
        </p>
        <div className='line'></div>
        <p className='info'>
          <Icon name='marker' />
          {user?.email}
        </p>
        <div className='line'></div>

        <h4 style={{ marginTop: 15 }}>Informations</h4>
        <div className='avatar'>
          <img src={user?.profilePicture} alt='' />
          <div>
            <h4>
              {user?.first_name} {user?.last_name}
            </h4>
            <p>Directeur commerciale</p>
          </div>
        </div>
      </div>
      <div className='body'>
        <h4>Documents</h4>

        <div className='app'>
          {listMessages
            ?.filter((m) => m?.Type === 'file')
            .map((m, i) => (
              <div className='avatar' key={i}>
                <img src={fileType(m?.message)} alt='' />
                <div>
                  <h4>{m?.message?.replace(/^.*[\\/]/, '')}</h4>
                  <p>{m?.message?.split('.')?.pop()}</p>
                </div>
                {/* <button>
                  <Icon name='menu-dots-vertical' />
                </button> */}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default RightDescution;
