import React from 'react';
import ReactApexChart from 'react-apexcharts';
import './Chart.css';

function ChartBar({ dashboard = {} }) {
  const { monthlyTotals } = dashboard;

  const series = [
    {
      name: 'Rachid MOUFAKKIR',
      data: monthlyTotals?.map((item, i) => item?.total) || [],
    },
  ];

  const getBarColors = () => {
    return series[0].data.map((value) =>
      value >= 29250 ? '#E0FF22' : '#000000'
    );
  };

  const data = {
    series,
    options: {
      chart: {
        height: 29.25,
        type: 'bar',
        width: '100%',
        stacked: false,

        toolbar: {
          show: false,
        },
      },

      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          borderRadius: 5,
          borderRadiusOnAllStackedSeries: true,
          distributed: true,
        },
      },

      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: 4,
        opacity: 0,
      },

      xaxis: {
        categories: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ],
      },

      yaxis: {
        labels: {
          formatter: function (value) {
            return value / 1000 + 'K';
          },
          style: {
            colors: [
              '#72777B',
              '#72777B',
              '#72777B',
              '#72777B',
              '#72777B',
              '#72777B',
              '#72777B',
              '#72777B',
              '#72777B',
              '#72777B',
              '#72777B',
              '#72777B',
            ],
          },
        },
      },

      colors: getBarColors(),
    },
  };

  return (
    <div className={`chart `}>
      <div className='flex'>
        <div>Revenu total mensuel</div>
      </div>

      <div className='chart-container'>
        <ReactApexChart
          options={data.options}
          series={data.series}
          type={'bar'}
          width={'100%'}
          height={'100%'}
        />
      </div>
      <div className='flex'>
        <p></p>
        <p>
          <span style={{ background: '#E0FF22' }}></span> Croissance
        </p>
        <p>
          <span></span> Déclin
        </p>
        <p></p>
      </div>
    </div>
  );
}

export default ChartBar;
