import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
// import pdfDoc from './Facture.pdf';

async function modifyPdf({
  name,
  ice,
  number,
  description,
  subtotal = 1000,
  date,
}) {
  // Load an existing PDF document
  const existingPdfBytes = await fetch('/Facture.pdf').then((res) =>
    res.arrayBuffer()
  );

  const pdfDoc = await PDFDocument.load(existingPdfBytes);
  const helveticaFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);

  // Get the first page of the document
  const pages = pdfDoc.getPages();
  const firstPage = pages[0];

  const { width, height } = firstPage.getSize();

  // Draw some text on the first page
  firstPage.drawText(ice || '', {
    x: 100,
    y: 548,
    size: 10,

    // color: rgb(0, 0.53, 0.71),
  });

  firstPage.drawText(name || '', {
    x: 100,
    y: 561,
    size: 10,

    // color: rgb(0, 0.53, 0.71),
  });

  firstPage.drawText(date || '', {
    x: 130,
    y: 615,
    size: 10,

    // color: rgb(0, 0.53, 0.71),
  });

  firstPage.drawText(date || '', {
    x: 140,
    y: 632,
    size: 10,

    // color: rgb(0, 0.53, 0.71),
  });

  firstPage.drawText((number || '')?.toString(), {
    x: 175,
    y: 650,
    size: 14,
    font: helveticaFont,

    // color: rgb(0, 0.53, 0.71),
  });

  firstPage.drawText(
    `PACK DE LANCEMENT MAPLEAD: Visite Virtuelle, Pack Photos HDR, Publication (Google Street View), Intégration de Menu Interactif, Mise à jour annuelle, Vidéo de Restaurant`,
    {
      x: 45,
      y: 444,
      size: 9,
      lineHeight: 10,
      maxWidth: 400,
      // color: rgb(0, 0.53, 0.71),
    }
  );
  firstPage.drawText((subtotal || 0)?.toFixed(2) + ' Dhs', {
    x: 475,
    y: 444,
    size: 9,
    lineHeight: 10,
    maxWidth: 400,
    // color: rgb(0, 0.53, 0.71),
  });

  firstPage.drawText(
    "Cloud 10GB d'espace, Mattertags illimités, Statistiques d'engagements",
    {
      x: 45,
      y: 414,
      size: 9,
      lineHeight: 10,
      maxWidth: 400,
      // color: rgb(0, 0.53, 0.71),
    }
  );

  firstPage.drawText('0.00 Dhs', {
    x: 480,
    y: 414,
    size: 9,
    lineHeight: 10,
    maxWidth: 400,
    // color: rgb(0, 0.53, 0.71),
  });

  let text = (subtotal || 0)?.toFixed(2) + ' Dhs';
  let textWidth = helveticaFont.widthOfTextAtSize(text, 10);
  firstPage.drawText(text, {
    x: 530 - textWidth,
    y: 388,
    size: 9,
    lineHeight: 10,
    maxWidth: 400,
    font: helveticaFont,
  });

  text = ((20 / 100) * (subtotal || 0))?.toFixed(2) + ' Dhs';
  textWidth = helveticaFont.widthOfTextAtSize(text, 10);
  firstPage.drawText(text, {
    x: 530 - textWidth,
    y: 372,
    size: 9,
    lineHeight: 10,
    maxWidth: 400,
    font: helveticaFont,
  });

  text = '0.00 Dhs';
  textWidth = helveticaFont.widthOfTextAtSize(text, 10);
  firstPage.drawText(text, {
    x: 530 - textWidth,
    y: 357,
    size: 9,
    lineHeight: 10,
    maxWidth: 400,
    font: helveticaFont,
  });

  text = ((20 / 100 + 1) * (subtotal || 0))?.toFixed(2) + ' Dhs';
  textWidth = helveticaFont.widthOfTextAtSize(text, 10);
  firstPage.drawText(text, {
    x: 530 - textWidth,
    y: 340,
    size: 9,
    lineHeight: 10,
    maxWidth: 400,
    font: helveticaFont,
  });

  // Serialize the PDFDocument to bytes (a Uint8Array)
  const pdfBytes = await pdfDoc.save();

  // For example, you can now download the modified PDF
  const blob = new Blob([pdfBytes], { type: 'application/pdf' });
  const url = URL.createObjectURL(blob);
  window.open(url);
  // return { url };
}

export { modifyPdf };
