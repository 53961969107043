const token_local = () => {
  const token = localStorage.getItem('token');
  return token;
};

const profile_local = () => {
  const { _id, email, role } = JSON.parse(localStorage.getItem('user') || '{}');

  return { _id, email, role };
};

export { token_local, profile_local };
