import React, { useState } from 'react';
import './BodyDescution.css';
import { useForm } from 'react-hook-form';
import Icon from '../../../components/Icon/Icon';
import { useQuery } from 'react-query';
import Upload from '../../../components/Upload/Upload';
import { Link, json } from 'react-router-dom';
import { fileType } from '../../../utils/fileType';

function BodyDescution({
  listUsersConnected,
  user,
  listMessages,
  sendMessage,
}) {
  const online = listUsersConnected?.includes(user?._id);
  console.log({ listMessages });
  const [upload, setUpload] = useState('');
  const { register, handleSubmit } = useForm();
  const { data: profile } = useQuery('profile');

  return (
    <div className='BodyDescution'>
      <div className='top'>
        <div className='avatar'>
          <img src={user?.profilePicture || '/images/user.png'} alt='' />
          <div>
            <h4>
              {user?.first_name} {'  '}
              {user?.last_name}
            </h4>
            <p>
              <span className={online ? 'active' : ''}></span>{' '}
              {online ? 'Connecté' : ' Deconnecté'}
            </p>
          </div>
        </div>

        <button
          onClick={() => {
            document
              .querySelector('.Descution .overlay')
              .classList.add('active');
            document.querySelector('.RightDescution').classList.add('active');
          }}
        >
          <Icon name='menu-dots-vertical' />
        </button>
      </div>
      <div className='body'>
        {listMessages?.map((m, i) => (
          <div
            key={i}
            className={`message ${m?.from === user?._id ? '' : 'right'}`}
          >
            {listMessages?.[i - 1]?.from !== m?.from ? (
              <img
                className='user'
                src={
                  (m?.from === user?._id
                    ? user?.profilePicture
                    : profile?.profilePicture) || '/images/user.png'
                }
                alt=''
              />
            ) : (
              <div className='user'></div>
            )}
            {m?.Type === 'file' || m?.type === 'file' ? (
              <Link to={m?.message} download={true} target='_blank'>
                <img
                  className='file'
                  src={fileType(m?.message) || '/images/user.png'}
                />
              </Link>
            ) : (
              <p>{m?.message}</p>
            )}
          </div>
        ))}
      </div>
      <div className='footer'>
        <button>
          {upload && <span id={`count-${upload}`}></span>}
          <Upload
            {...{
              onUpload: (url) => {
                sendMessage({ message: url, type: 'file' });
                setUpload('');
              },
              onStartUpload: (id) => {
                setUpload(id);
              },
              onError: () => {},
              isImage: false,
            }}
          />
          <Icon name='link' />
        </button>
        <form id='form-message' onSubmit={handleSubmit(sendMessage)}>
          <input
            placeholder='Tapez votre message'
            type='text'
            required
            {...register('message')}
          />
          <button>
            <Icon name='send' />
          </button>
        </form>
      </div>
    </div>
  );
}

export default BodyDescution;
