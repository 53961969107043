import React, { useState } from 'react';
import Icon from '../Icon/Icon';
import './Notifications.css';
import { useQuery } from 'react-query';
import { confirmNotification, deleteNotification } from '../../utils/api/users';
import moment from 'moment';
import { useChat } from '../../hooks/useChat';

function Notifications() {
  const [Show, setShow] = useState(false);
  const [ShowNotificationCount, setShowNotificationCount] = useState(true);
  const { data: profile } = useQuery('profile');
  const {} = useChat();

  const last_visit = localStorage.getItem('last_visit');

  const notification =
    profile?.notification?.length > 0
      ? [...profile?.notification]?.reverse()
      : null;

  const count_notif =
    notification?.length +
    1 -
    (parseInt(localStorage.getItem('notificationCount')) || 0);

  return (
    <div className={`Notifications `}>
      <button
        onClick={() => {
          setShow(true);
          setShowNotificationCount(false);
          // Retrieve the current count from localStorage
          let currentCount = notification?.length + 1;
          // Update localStorage with the new count
          localStorage.setItem('notificationCount', currentCount);
        }}
      >
        <Icon name='bell' />
        {count_notif > 0 && <span>{count_notif > 9 ? '+9' : count_notif}</span>}
      </button>
      {Show && (
        <>
          <div className='overlay' onClick={() => setShow(false)}></div>
          <div className='list'>
            {notification ? (
              notification?.map((p, i) => (
                <div className='item' key={i}>
                  {p?.img && (
                    <img
                      height={30}
                      style={{
                        borderRadius: '50%',
                        objectFit: 'cover',
                        border: 'solid 1px #eee',
                      }}
                      width={30}
                      src={p?.img}
                      alt=''
                      srcset=''
                    />
                  )}
                  <div>
                    {p?.message} <br />
                    <span>{p?.body}</span>
                    <p>{moment(p?.date)?.format('DD/MM/YYYY hh:mm')}</p>
                  </div>
                  <button
                    className=''
                    style={{ color: 'red', fontSize: 20, display: 'flex' }}
                    onClick={() => {
                      deleteNotification(p?.operationId || p?._id).catch(
                        (err) => console.log(err)
                      );
                      // Retrieve the current notification count from localStorage
                      let storedCount =
                        localStorage.getItem('notificationCount');
                      let newCount = storedCount
                        ? parseInt(storedCount) - 1
                        : 0;

                      // Ensure the new count is not negative
                      if (newCount < 0) newCount = 0;

                      // Update localStorage with the new count
                      localStorage.setItem('notificationCount', newCount);
                    }}
                  >
                    <Icon name='trash' />
                  </button>
                </div>
              ))
            ) : (
              <div className='item'>
                <div>
                  Notifications
                  <p>Tu n'as aucune notification.</p>
                </div>
                <button onClick={() => setShow(false)}>
                  <Icon name='close' />
                </button>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default Notifications;
