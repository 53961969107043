import React, { useState } from 'react';

import { APIProvider } from '@vis.gl/react-google-maps';
import { PlaceAutocompleteClassic } from './AutoComplet';

function MapsSearch({
  position = { lat: 34, lng: -6 },
  setPosition = ({ lat, lng }) => {},
}) {
  return (
    <APIProvider apiKey={'AIzaSyA0v1gP-rlYNhnR8fUT5cyLYqIHqxGNOKo'}>
      <PlaceAutocompleteClassic
        onPlaceSelect={(v) => {
          console.log(v);
          setPosition({
            lat: v?.geometry?.location?.lat(),
            lng: v?.geometry?.location?.lng(),
            formatted_address: v?.formatted_address,
          });
        }}
      />
    </APIProvider>
  );
}

export default MapsSearch;
