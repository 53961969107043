import React from 'react';
import Icon from '../../../components/Icon/Icon';
import { useForm } from 'react-hook-form';
import Select from '../../../components/Inputs/Select';
import SelectClient from '../../../components/SelectUsers/SelectClient';
import {
  createAgenda,
  getAgenda,
  updateAgenda,
} from '../../../utils/api/agenda';
import SelectUser from '../../../components/SelectUsers/SelectUser';
import moment from 'moment';
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { useQuery } from 'react-query';
import MapsSearch from '../../../components/Maps/MapsSearch';

function AddCalendar() {
  const { id, type } = useParams();
  const [params] = useSearchParams();
  const [date, startTime, endTime] = [
    params.get('date') || '',
    params.get('startTime') || '',
    params.get('endTime') || '',
  ];

  const router = useNavigate();

  const { data: event } = useQuery(`agenda-${id}`, () =>
    !id ? null : getAgenda(id)
  );

  const { data: user } = useQuery(`profile`);
  const { control, register, handleSubmit, setValue, watch } = useForm({
    values: event || {
      date,
      startTime,
      endTime,
      type,
      organisateur: user?._id,
      user_name: user?.first_name + ' ' + user?.last_name,
    },
  });

  return (
    <>
      <Link
        className='overlay'
        to={`/agenda/${type || 'event'}/ajouter`}
      ></Link>

      <form
        className='add-rdv'
        onSubmit={handleSubmit(
          id
            ? (data) =>
                updateAgenda(data)
                  .then(() => {
                    router('/agenda/' + type);
                  })
                  .catch((err) => {
                    console.log(err);
                  })
            : (data) =>
                createAgenda(data)
                  .then(() => {
                    router('/agenda/' + type);
                  })
                  .catch((err) => {
                    console.log(err);
                  })
        )}
      >
        <h4>
          Ajouter un {watch('type') === 'event' ? 'événement' : 'Rendez-vous'}
          <Link to={`/agenda/${type || 'event'}/ajouter`}>
            {' '}
            <Icon name='cross-circle' />
          </Link>
        </h4>
        <input
          {...register('title')}
          required
          type='text'
          placeholder={`Titre de ${
            watch('type') === 'event' ? 'événement' : 'Rendez-vous'
          }`}
        />
        {/* <input
          {...register('organisateur')}
          type='text'
          placeholder=
        /> */}
        {user?.role === 'Admin' ? (
          <SelectUser
            {...{
              name: 'organisateur',
              register,
              setValue,
              watch,
              required: true,
              lable: 'Organisateur de l’événement',
            }}
          />
        ) : (
          <>
            <input
              type='hidden'
              name=''
              value={user?._id}
              {...register('organisateur')}
            />
            <input
              type='hidden'
              name=''
              value={user?.first_name + ' ' + user?.last_name}
              {...register('user_name')}
            />
          </>
        )}
        <SelectClient
          {...{
            name: 'client',
            register,
            setValue,
            watch,
            required: true,
            lable: 'Nom du client',
          }}
        />

        <div className='time'>
          <div className='top'>
            <div className='flex'>
              <p>
                <Icon name='clock' />
                Date & temps
              </p>
              {/* <div className='select'>
                Fuseau horaire:
                <select name='' id='' {...register('fuseau')}>
                  <option value='casa'>Casablanca (+1 GMT)</option>
                </select>
              </div> */}
            </div>
          </div>
          <div className='body'>
            <label htmlFor=''>Date de l’événement</label>
            <input
              type='date'
              {...register('date')}
              value={moment(watch('date'))?.format('YYYY-MM-DD')}
              placeholder='Choisissez une date'
              required
            />

            <div className='flex'>
              <div>
                <label htmlFor=''>De</label>
                <input
                  style={{
                    minHeight: 32,
                  }}
                  type='time'
                  {...register('startTime')}
                  min={'08:00'}
                  max={'18:00'}
                  defaultValue={'08:00'}
                  required
                />
              </div>
              <div>
                <label htmlFor=''>à</label>
                <input
                  style={{
                    minHeight: 32,
                  }}
                  type='time'
                  {...register('endTime')}
                  min={watch('startTime') || '08:00'}
                  max={'18:00'}
                  defaultValue={'09:00'}
                  required
                />
              </div>
            </div>
          </div>
        </div>
        <div className='input'>
          <Icon name='marker' />
          <MapsSearch
            position={watch('localisation')}
            setPosition={(p) => setValue('localisation', p?.formatted_address)}
          />

          <input value={watch('localisation')} type='text' />
        </div>

        {watch('type') === 'event' && (
          <div className='input'>
            <Icon name='user-add' />
            <input
              type='text'
              {...register('participants')}
              placeholder='Ajouter des participants'
            />
          </div>
        )}

        {/* {watch('type') === 'event' && (
          <div className='time'>
            <div className='top'>
              <div className='flex' style={{ justifyContent: 'center' }}>
                <p>
                  <Icon name='rappel' />
                  Rappel
                </p>
              </div>
            </div>
            <div className='body'>
              <div className='flex' style={{ gap: 4 }}>
                <p>On</p>

                <button type='button'>
                  <input type='checkbox' {...register('day.0')} /> M
                </button>
                <button type='button'>
                  <input type='checkbox' {...register('day.1')} /> T
                </button>
                <button type='button'>
                  <input type='checkbox' {...register('day.2')} /> W
                </button>
                <button type='button'>
                  <input type='checkbox' {...register('day.3')} /> T
                </button>
                <button type='button'>
                  <input type='checkbox' {...register('day.4')} /> F
                </button>
                <button type='button'>
                  <input type='checkbox' {...register('day.5')} /> S
                </button>
                <button type='button'>
                  <input type='checkbox' {...register('day.6')} /> S
                </button>
              </div>
            </div>
          </div>
        )} */}
        <div className='flex-buttons'>
          <Link to={`/agenda/${type || 'event'}/ajouter`}>Annuler</Link>
          <button type='submit' style={{ padding: ' 0 20px' }}>
            Sauvegarder
          </button>
        </div>
      </form>
    </>
  );
}

export default AddCalendar;
