import { Outlet } from 'react-router-dom';
import AppLayout from '../AppLayout';
import CaptureService from '../../screens/CaptureService/CaptureService';

const captureRouter = {
  path: '/capture_service',
  element: (
    <AppLayout>
      <Outlet />
    </AppLayout>
  ),
  children: [
    {
      path: '',
      element: <CaptureService />,
    },
    {
      path: ':id',
      element: <CaptureService />,
    },
    {
      path: ':id/:action',
      element: <CaptureService />,
    },
  ],
};

export { captureRouter };
