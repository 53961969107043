import { React, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import FormUser from './FormUser/FormUser';
import { getUser, updateProfile, updateUser } from '../../utils/api/users';
import { ajouterDocument } from '../../utils/api/documents';

function UpdateUser() {
  const { id } = useParams();
  const { data: profile } = useQuery(`profile`);
  const { data: user } = useQuery(`user/${id}`, () => getUser({ id }));

  console.log({ user, id });

  const navigate = useNavigate();
  const handleFormSubmit = async (data) => {
    if (id === profile?._id)
      return updateProfile({ data })
        .then(() => navigate('/'))
        .catch((err) => {
          console.log(err);
        });

    return updateUser({ id, data })
      .then(({ data }) => {
        navigate('/');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (user)
    return (
      <FormUser
        id={id}
        user={user}
        profile={profile}
        handleFormSubmit={handleFormSubmit}
      />
    );
}

export default UpdateUser;
