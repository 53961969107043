import { popup } from '../../components/Popup/Popup';
import { queryClient } from '../../layouts/ReactQueryLayout';
import { Axios } from './axios';

const getAgendas = () => {
  return Axios.get(`/visit`).then(({ data }) => {
    console.log({ Agendas: data });
    return data;
  });
};

const createAgenda = (data) => {
  return Axios.post(`/visit`, data).then(({ data }) => {
    console.log({ Agenda: data });
    queryClient.invalidateQueries('agendas');

    popup.show({
      title: 'Ajouter',
      type: 'notification',
      message: 'Votre événement a été créée avec succès.',
    });
    return data;
  });
};

const updateAgenda = (data) => {
  return Axios.put(`/visit/${data?._id}`, {
    ...data,
    client: data?.client?._id,
  }).then(({ data }) => {
    console.log({ Agenda: data });
    queryClient.invalidateQueries('agendas');
    popup.show({
      title: 'Ajouter',
      type: 'notification',
      message: 'Votre capture a été modifiée avec succès.',
    });
    return data;
  });
};

const getAgenda = (id) => {
  return Axios.get(`/visit/${id}`).then(({ data }) => {
    console.log({ Agendas: data });
    return data;
  });
};

export { getAgendas, createAgenda, updateAgenda, getAgenda };
