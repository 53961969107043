import { popup } from '../../components/Popup/Popup';
import { queryClient } from '../../layouts/ReactQueryLayout';
import { Axios } from './axios';

const createGoogleStreet = (data) => {
  return Axios.post(`/sreetview`, data).then(({ data }) => {
    // queryClient.invalidateQueries('captures');
    if (data?.id)
      popup.show({
        title: 'Ajouter',
        type: 'notification',
        message: 'Video uploaded successfully',
        // button_name: 'Aperçus',
        // button_action: () => window.open(data?.shareLink),
      });
    return data;
  });
};

const getlistVideos = () => {
  return Axios.get(`/sreetview`).then(({ data }) => {
    console.log({ StreetView: data });
    return data;
  });
};

export { createGoogleStreet, getlistVideos };
