import React from 'react';
import SideBar from '../components/SideBar/SideBar';
import Header from '../components/Header/Header';

function AppLayout({ children, show_logo_only }) {
  return (
    <div className={`app-container  ${show_logo_only ? 'show_logo_only' : ''}`}>
      <SideBar />
      <div className='app-contents'>
        <Header />
        <div className='app-body'>{children}</div>
      </div>
    </div>
  );
}

export default AppLayout;
