import React, { useState } from 'react';
import './Authentification.css';
import { Link } from 'react-router-dom';
import Icon from '../../components/Icon/Icon';
import { useForm } from 'react-hook-form';
import AuthInfo from '../../components/auth_info/AuthInfo';

import { forgotPassword, login } from '../../utils/api/auth';

function Forgot() {
  const { register, handleSubmit, watch } = useForm({
    defaultValues: { role: 'Admin' },
  });
  const [ShowPassword, setShowPassword] = useState(false);
  const [roles, setRoles] = useState([
    {
      id: '1',
      nom: 'Technicien',
    },
    {
      id: '2',
      nom: 'Propriétaire',
    },
    {
      id: '3',
      nom: 'Locataire',
    },
    {
      id: '0',
      nom: 'Admin',
    },
  ]);

  return (
    <div className='Login'>
      <AuthInfo />
      <div className='Form-container'>
        <form onSubmit={handleSubmit(forgotPassword)}>
          <div className='form-items'>
            <h2>Mot de passe oublié</h2>
            <div className='inputs'>
              <div className='input-container'>
                <label htmlFor=''>Email</label>
                <input
                  {...register('email')}
                  required
                  type='email'
                  placeholder='Entrer votre email'
                />
              </div>
            </div>
            <div className='buttons'>
              <button type='submit' className='primary-color '>
                Envoyer
              </button>
              {/* <button className="primary-color">
                <Icon name="google" />
                Continuer avec Google
              </button>
              <button className="primary-color">
                <Icon name="facebook" />
                Continuer avec Facebook
              </button> */}
              {/* <h4>
                Vous n’avez pas de compte ?
                <Link to={'register'}>Créer un compte</Link>
              </h4> */}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Forgot;
