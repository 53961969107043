// Popup.js
import React, { useState } from 'react';
import './Popup.css';
import Icon from '../Icon/Icon';

const popup = {
  show: ({
    title = 'Supprimé',
    action = () => {},
    type = 'notification',
    message = 'Votre commande a été effectuée avec succès',
  }) => {},
  hide: () => {},
};

function Popup() {
  const [state, setstate] = useState({
    show: false,
    title: '',
    action: () => {},
    type: 'notification',
    message: 'Votre commande a été effectuée avec succès',
  });

  popup.show = ({
    title = 'Supprimé',
    action = () => {},
    type = 'notification',
    message = 'Votre commande a été effectuée avec succès',
    button_action = () => {},
    button_name = '',
  }) => {
    setstate({
      show: true,
      title,
      action,
      type,
      message,
      button_action,
      button_name,
    });
  };

  popup.hide = () => {
    setstate({ show: false });
  };

  if (!state?.show) return <></>;

  if (state?.type === 'notification')
    return (
      <div className='Popup-container'>
        <div className='overlay active' onClick={popup.hide}></div>
        <div className='Popup'>
          <h2>
            <Icon name='check' />
            {state?.title}
          </h2>
          <p>{state?.message}</p>
          <div className='buttons'>
            <button onClick={popup.hide}>Fermer</button>
            {state.button_name && (
              <button onClick={state.button_action}>{state.button_name}</button>
            )}
          </div>
        </div>
      </div>
    );

  if (state?.type === 'confirmation')
    return (
      <div className='Popup-container'>
        <div className='overlay active' onClick={popup.hide}></div>
        <div className='Popup Delete'>
          <div className='trash'>
            <Icon name='trash' />
          </div>
          <h4>{state?.title} </h4>
          <h5>
            Êtes-vous sûr de vouloir supprimer cet utilisateur du système?
          </h5>
          <p>
            Cette action est irréversible et toutes les données associées seront
            perdues. Veuillez confirmer votre choix pour procéder à la
            suppression.
          </p>
          <div className='buttons'>
            <button onClick={popup.hide}>Annuler</button>
            <button onClick={state.action}>Supprimer</button>
          </div>
        </div>
      </div>
    );
}

export default Popup;

export { popup };
