import { Outlet } from 'react-router-dom';
import AppLayout from '../AppLayout';
import Users from '../../screens/Users/Users';
import AddUser from '../../screens/Users/AddUser';
import UpdateUser from '../../screens/Users/UpdateUser';
import Agenda from '../../screens/Agenda/Agenda';
import AgendaSelect from '../../screens/Agenda/AgendaSelect';

const agendaRouter = {
  path: '/agenda',
  element: (
    <AppLayout>
      <Outlet />
    </AppLayout>
  ),
  children: [
    {
      path: '',
      element: <Agenda />,
    },
    {
      path: ':type',
      element: <Agenda />,
    },
    {
      path: ':type/ajouter',
      element: <AgendaSelect />,
    },
    {
      path: ':type/:id',
      element: <AgendaSelect />,
    },
  ],
};

export { agendaRouter };
