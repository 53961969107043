import { React, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';

import { useQuery } from 'react-query';

import FormUser from './FormUser/FormUser';
import { createUser } from '../../utils/api/users';
import { ajouterDocument } from '../../utils/api/documents';

function AddUser() {
  const { data: profile } = useQuery(`profile`);
  console.log('first', profile);
  const navigate = useNavigate();
  const handleFormSubmit = async (data) => {
    return createUser({ data })
      .then(({ data }) => {
        navigate('/');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <FormUser
      id={''}
      user={{ role: 'Technicien', status: false }}
      profile={profile}
      handleFormSubmit={handleFormSubmit}
    />
  );
}

export default AddUser;
