import React from 'react';

const AgendaImg = ({ number = '01' }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      viewBox='0 0 225 225'
      height={50}
    >
      <defs>
        <style
          dangerouslySetInnerHTML={{
            __html:
              '\n            .cls-1 {\n                fill: url(#Dégradé_sans_nom_5);\n            }\n\n            .cls-2 {\n                font-size: 80px;\n                fill: #444243;\n                \n            }\n        ',
          }}
        />
        <linearGradient
          id='Dégradé_sans_nom_5'
          x1='115.26'
          y1='84.26'
          x2='114.84'
          y2='186.16'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0} stopColor='#f9fafa' />
          <stop offset={1} stopColor='#eeeeed' />
        </linearGradient>
      </defs>
      <title>Fichier 2logo</title>
      <g id='Calque_2' data-name='Calque 2'>
        <g id='Calque_1-2' data-name='Calque 1'>
          <image width={225} height={225} xlinkHref='/images/agenda.png' />
          <rect
            className='cls-1'
            x='64.12'
            y='89.2'
            width='101.9'
            height='82.65'
          />
          <text
            className='cls-2'
            style={{ fontStyle: 'normal', fontWeight: 700 }}
            transform='translate(64.12 162.94)'
          >
            {number}
          </text>
        </g>
      </g>
    </svg>
  );
};

export default AgendaImg;
