import React from 'react';
import './Descution.css';
import { useChat } from '../../../hooks/useChat';
import LeftDescution from '../LeftDescution/LeftDescution';
import BodyDescution from '../BodyDescution/BodyDescution';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getUser } from '../../../utils/api/users';
import RightDescution from '../RightDescution/RightDescution';

const Descution = () => {
  const { id } = useParams();
  const { data: user } = useQuery(`user-${id}`, () => getUser({ id }));
  const { listUsers, listMessages, listUsersConnected, chatUser, sendMessage } =
    useChat();

  return (
    <div className='Descution'>
      <LeftDescution
        listUsers={listUsers}
        listUsersConnected={listUsersConnected}
        user={user}
      />
      <BodyDescution
        listUsersConnected={listUsersConnected}
        user={user}
        listMessages={listMessages}
        sendMessage={sendMessage}
      />
      <RightDescution listMessages={listMessages} />
      <div
        className='overlay'
        onClick={() => {
          document.querySelector(' .RightDescution').classList.remove('active');
          document
            .querySelector('.Descution .overlay')
            .classList.remove('active');
        }}
      ></div>
    </div>
  );
};

export default Descution;
