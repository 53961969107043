import React from 'react';
import './LeftDescution.css';
import Icon from '../../../Icon';
import moment from 'moment';
import { Link, useParams } from 'react-router-dom';

function LeftDescution({ listUsers = [], listUsersConnected = [], user }) {
  const { id } = useParams();

  return (
    <div className='LeftDescution'>
      <div className='top'>
        <h3>
          Messages <span>02</span>
        </h3>

        <Link to={'/chat'}>
          <button>
            <Icon name='plus' />
          </button>
        </Link>
      </div>
      <div className='body'>
        {listUsers?.map((user, i) => (
          <Link
            to={`/chat/discution/${user?._id}`}
            className={`avatar ${id === user?._id ? 'active' : ''}`}
            key={i}
          >
            <span className='img'>
              <span
                className={
                  listUsersConnected?.includes(user?._id) ? 'active' : ''
                }
              ></span>{' '}
              <img src={user?.profilePicture || '/images/user.png'} alt='' />
            </span>
            <div style={{ flex: 1 }}>
              <h4>
                {user?.first_name} {user?.last_name}
                <span>
                  {moment
                    .duration(moment().diff(moment(user?.last_message?.Time)))
                    .humanize()}
                </span>
              </h4>
              {user?.last_message?.type === 'file' ||
              user?.last_message?.Type === 'file' ? (
                <Icon name='file' />
              ) : (
                <p>{user?.last_message?.message}</p>
              )}
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default LeftDescution;
