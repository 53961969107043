import React from 'react';
import { Link } from 'react-router-dom';
import Icon from '../Icon/Icon';

function AuthInfo({ login = true }) {
  let users_imgs = [
    '/images/users/jessica-felicio-_cvwXhGqG-o-unsplash.png',
    '/images/users/aiony-haust-3TLl_97HNJo-unsplash.png',
    '/images/users/christopher-campbell-rDEOVtE7vOs-unsplash.png',
    '/images/users/christopher-campbell-rDEOVtE7vOs-unsplash-1.png',
  ];
  return (
    <div className='Info-container'>
      <div className='Logo'>
        <img src={'/images/Logo.png'} alt='' />
      </div>
      <div className='Info'>
        <h1>
          OPTIMISEZ VOTRE <span>GESTION</span> <span>RESTAURANT</span> AVEC DES
          OUTILS CONÇUS POUR LES PROS
        </h1>
        <h5>Conçue pour la performance, au service de la gestion.</h5>
        <div className='users'>
          {users_imgs?.map((item) => (
            <div className='user' key={item}>
              <img src={item} alt='' />
            </div>
          ))}
          <div className='user'>
            <div className='items'>+12</div>
          </div>
        </div>
        {/* <div className='new-account'>
          <h6>
            {login ? 'Vous n’avez pas de compte' : 'Vous avez déjà un compte ?'}
          </h6>
          <Link to={login ? '/register' : '/'}>
            <span>{login ? ' Créer un compte' : ' Se connecter'} </span>{' '}
            <Icon name='next' />
          </Link>
        </div> */}
      </div>
      <div className='home-3d' style={{ minHeight: 200 }}>
        <img src='https://approuas.web.app/images/iPhone.png' alt='' />
        {/* <img src='https://approuas.web.app/images/authImage.svg' alt='' /> */}
      </div>
    </div>
  );
}

export default AuthInfo;
