import React from 'react';
import './Counts.css';
import Icon from '../../Icon/Icon';
import { utils, writeFile } from 'xlsx-js-style';

function Counts({ dashboard = {}, isLoading = false }) {
  const { Revenu, Commandes, monthlyTotals, utilisateurs } = dashboard;
  var months = [
    'Janvier',
    'Fevrier',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
  ];

  const ExportJson = () => {
    // var ws = utils.json_to_sheet(
    //   [
    //     { v: 'Courier: 24', t: 's', s: { font: { name: 'Courier', sz: 24 } } },
    //     {
    //       v: 'Bold & Red',
    //       t: 's',
    //       s: { font: { bold: true, color: { rgb: 'FF0000' } } },
    //     },
    //     {
    //       v: 'Fill Color',
    //       t: 's',
    //       s: { fill: { fgColor: { rgb: 'E9E9E9' } } },
    //     },
    //     { v: 'Wrap Text', t: 's', s: { alignment: { wrapText: true } } },
    //   ]?.map((item) => ({
    //     ...item,
    //     month: months[(item?.month || 1) - 1],
    //   })) || []
    // );
    var wb = utils.book_new();

    const data = [
      [
        {
          v: 'Ventes du Mois',
          t: 's',
          s: {
            font: { sz: 12, name: 'Arial', color: { rgb: '15668B' } },
            alignment: { horizontal: 'center', vertical: 'center' },
          },
        },
        {
          v: 'Commandes',
          t: 's',
          s: {
            font: { sz: 12, name: 'Arial', color: { rgb: '15668B' } },
            alignment: { horizontal: 'center', vertical: 'center' },
          },
        },
      ],
      ...monthlyTotals?.map((item) => [
        {
          v: months?.[(item?.month || 1) - 1],
          t: 's',
          s: {
            font: { sz: 12, name: 'Arial' },
            alignment: { vertical: 'center' },
          },
        },
        {
          v: item?.total?.toFixed(2),
          t: 's',
          s: {
            font: { sz: 12, name: 'Arial' },
            alignment: { vertical: 'center', horizontal: 'right' },
          },
        },
      ]),
    ];

    // Convert data to a worksheet
    const ws = utils.aoa_to_sheet(data);

    // Set column widths
    ws['!cols'] = [
      { wch: 35 }, // Column 1 width
      { wch: 35 }, // Column 2 width
    ];

    utils.book_append_sheet(wb, ws, 'Styled Sheet');
    writeFile(wb, 'Statistiques-Maplead.xlsx');
  };

  if (isLoading)
    return (
      <div className='LoadingAnime'>
        <div className='counts'>
          {[1, 1, 1, 1].map((_, i) => (
            <div className='count' key={i}>
              <div className='icon anime'></div>
              <div className='data'>
                <h5 className='anime'></h5>
                <span className='anime'></span>
              </div>
            </div>
          ))}
        </div>
      </div>
    );

  return (
    <div className='chart'>
      <div style={{ width: 'fit-content' }}>
        <div className='flex'>
          <div>
            Ventes du mois
            <p>Aperçu de ventes</p>
          </div>

          <button onClick={ExportJson}>
            <Icon name='upload' />
            Télécharger
          </button>
        </div>
        <div className='counts'>
          <div className='count'>
            <Icon name='dashboard-bg' />
            <div className='data'>
              <h5> {Revenu / 1000}k MAD</h5>
              <p>Total Sales</p>
              {/* <span>+8% depuis hier</span> */}
            </div>
          </div>
          <div className='count'>
            <Icon name='file-bg' />
            <div className='data'>
              <h5>{Commandes} </h5>
              <p>Commandes</p>
              {/* <span>+8% depuis hier</span> */}
            </div>
          </div>
          {/* <div className='count'>
            <Icon name='tag' />
            <div className='data'>
              <h5> 5</h5>
              <p>Produits vendus</p>
              <span>+8% depuis hier</span>
            </div>
          </div> */}
          <div className='count'>
            <Icon name='add-user' />
            <div className='data'>
              <h5>{utilisateurs}</h5>
              <p>Nouveau Clients</p>
              {/* <span>+8% depuis hier</span> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Counts;
