import { Axios, baseURL } from './axios';

const upload = (file, id) => {
  const formData = new FormData();
  formData.append('image', file);
  formData.append('base_url', baseURL);
  return Axios.post(`/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data', // Important for file uploads
    },

    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );

      try {
        document.querySelector('#id-' + id).style.width =
          percentCompleted + '%';
      } catch (error) {}

      try {
        document.querySelector('#count-' + id).innerHTML =
          percentCompleted + '%';
      } catch (error) {}
    },
  }).then(({ data }) => {
    console.log(data);
    return `${data?.file}`;
  });
};

const upload_64 = (e) => {
  return new Promise((resolve) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
    });
  });
};

const upload_file = (file, id) => {
  const formData = new FormData();

  formData.append('image', file);

  return Axios.post(`/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data', // Important for file uploads
    },
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      try {
        document.querySelector('#id-' + id).style.width =
          percentCompleted + '%';
        document.querySelector('#count-' + id).innerHTML =
          percentCompleted + '%';
      } catch (error) {}
    },
  }).then(({ data }) => {
    const { file, size } = data;

    return { file, size };
  });
};

export { upload, upload_64, upload_file };
