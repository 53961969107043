import React, { useState } from 'react';
import './FicheIntervention.css';
import Icon from '../../Icon/Icon';
import { Link } from 'react-router-dom';
import moment from 'moment';

function FicheIntervention({ value }) {
  let time = moment(value?.date).format('YYYY/MM/DD') + ' ' + value?.startTime;
  const days = moment(time).diff(moment());
  const Value =
    days > 0
      ? (100 * moment().diff(moment(value?.createdAt))) /
        moment(time).diff(moment(value?.createdAt))
      : 100;

  // moment(value?.date).diff(moment(value?.createdAt), 'days'));
  const steps = [
    'Fiche d’intervention envoyé',
    'Fiche d’intervention Accepté',
    'Visite initiale',
    'Début d’intervention',
    'Confirmation et signatures',
  ];

  return (
    <div className={`FicheIntervention ${days < 0 ? 'disabled' : ''} `}>
      <div className='flex-left'>
        <div className='timer'>
          <Icon name='caret-right' />
        </div>
        <div>
          <h5>à partir de</h5>
          <p>
            <Icon name='time-forward' />
            {value?.startTime}
          </p>
        </div>
      </div>
      <div className='flex-center'>
        <h4>
          {value?.title} - {value?.position}{' '}
        </h4>
        {Value >= 0 && (
          <>
            <div className='flex' style={{ color: '#768298', fontSize: 14 }}>
              <Icon name='copy' /> Tél: {value?.client?.telephone}
              <br /> Email: {value?.client?.email}
              <br /> Adresse: {value?.localisation}
            </div>
          </>
        )}
      </div>
      {/* {Value} */}
      {/* {Value >= 0 && ( */}
      <div className='flex-center'>
        <h3> {moment(value?.date)?.format('DD/MM/YYYY')}</h3>
        <span
          style={{
            textAlign: 'center',
            justifyContent: 'center',
            color: days > 0 ? '#2056ff' : '#0008',
          }}
        >
          {days > 0 ? `Dans ${moment.duration(days).humanize()}` : 'Passer'}
        </span>
        <div className='slider'>
          <div
            style={{
              width: `${Value}%`,
              background: days > 0 ? '#2056ff' : '#0008',
            }}
          ></div>
        </div>
      </div>
      {/* )} */}

      <div className='flex-center'>
        <Link to={`/agenda/randez-vous/${value?._id}`}>
          <button className='add '>
            <Icon name='eye' />
            Voir
          </button>
        </Link>
        {/* <Link to={`/agenda/randez-vous/${value?._id}`}>
          <button className='add black'>
            <Icon name='edit' />
            Gérer
          </button>
        </Link> */}
      </div>
    </div>
  );
}

export default FicheIntervention;
