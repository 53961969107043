import React, { useEffect, useState } from 'react';
import './Agenda.css';
import Counts from '../../components/Dashboard/Counts/Counts';
// import properties from "../Proprietes/properties.json";
import { useQuery, useQueryClient } from 'react-query';
import FicheIntervention from '../../components/Dashboard/FicheIntervention/FicheIntervention';
import { afficherTousFicheIntervention } from '../../utils/api/documents';
import '../Dashboard/Dashboard.css';
import Icon from '../../Icon';
import { getAgendas } from '../../utils/api/agenda';
import { Link, Navigate, useParams } from 'react-router-dom';
import SelectDateType from './SelectDateType';
import moment from 'moment';

function Agenda() {
  const [propertyWithId1, setPropertyWithId1] = useState(null);
  const { data: user } = useQuery('profile');
  const { data: agendas } = useQuery(`agendas`, getAgendas);
  const { type } = useParams();

  if (agendas?.length === 0) return <Navigate to={'/agenda/event/ajouter'} />;
  return (
    <div className='Dashboard'>
      <SelectDateType />
      <Link to={`/agenda/${type || 'event'}/ajouter`}>
        <button className='add' style={{ marginTop: 20, borderRadius: 9 }}>
          <Icon name='add' />
          Ajouter {type === 'randez-vous' ? 'rendez-vous' : 'événement '}
        </button>
      </Link>
      <div className='grid-dash'>
        {agendas
          ?.filter((v) => (type ? v?.type === type : v?.type === 'randez-vous'))
          ?.sort((v1, v2) => moment(v2?.date)?.diff(moment(v1?.date), 'days'))
          ?.map((f, i) => (
            <FicheIntervention key={i} value={f} />
          ))}

        {Array(5)
          ?.fill('')
          ?.map((item) => (
            <div
              className='FicheIntervention'
              style={{ background: '#0000' }}
            ></div>
          ))}
      </div>
    </div>
  );
}

export default Agenda;
