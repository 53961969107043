import React from 'react';
import './ChatCard.css';
import Icon from '../../../Icon';
import { Link } from 'react-router-dom';

function ChatCard({ user }) {
  return (
    <div className='ChatCard'>
      <img src={user?.profilePicture || '/images/user.png'} alt='' />
      <div className='body'>
        <h3 className='name'>
          {user?.first_name} {user?.last_name}
        </h3>
        <h4>{user?.role === 'Admin' ? 'Admin' : 'TechnoCommercial'}</h4>
        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.</p> */}
        <Link to={`/chat/discution/${user?._id}`}>
          <button className='add'>Contacter sur l’application</button>
        </Link>
        {/* <p className='or'>Où</p> */}
        {/* <div className='flex'>
          <button>
            <Icon name='facebook' />
          </button>
          <button>
            <Icon name='messenger' />
          </button>
          <button>
            <Icon name='whatsapp' />
          </button>
        </div> */}
      </div>
    </div>
  );
}

export default ChatCard;
