import { React, useState } from 'react';
import './FormUser.css';
import { useParams, useNavigate, Link } from 'react-router-dom';
import Icon from '../../../components/Icon/Icon';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { createUser, getUser, updateUser } from '../../../utils/api/users';
import Upload from '../../../components/Upload/Upload';
import { UserSchema } from '../../../utils/zod_check';
import { zodResolver } from '@hookform/resolvers/zod';
import { ajouterDocument } from '../../../utils/api/documents';

function FormUser({ id, profile, user, handleFormSubmit = () => {} }) {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ values: user || {}, resolver: zodResolver(UserSchema) });
  const roles = ['Admin', 'Propriétaire', 'Locataire', 'Technicien'];

  const handleDeleteImage = (index) => {
    setValue(
      'IdentityUrls',
      watch('IdentityUrls').filter((_, i) => i !== index)
    );
  };

  return (
    <>
      <div className='FormUser'>
        <div className='img-user'>
          <Upload
            onUpload={(url) => setValue('profilePicture', url)}
            src={watch('profilePicture')}
          />
          {watch('profilePicture') && (
            <>
              <button onClick={() => setValue('profilePicture', '')}>
                <Icon name='trash' /> Supprimer
              </button>
            </>
          )}
        </div>
        <form
          id='user'
          style={{ marginTop: 20 }}
          onSubmit={handleSubmit(handleFormSubmit)}
        >
          <div className='input-container'>
            <label htmlFor=''>Prénom</label>
            <input
              type='text'
              placeholder='Prénom'
              className={`${errors && errors['first_name'] ? 'error' : ''}`}
              {...register('first_name')}
            />
            <span className='error'>
              {`${
                errors && errors['first_name']?.message
                  ? errors['first_name']?.message
                  : ''
              }`}
            </span>
          </div>
          <div className='input-container'>
            <label htmlFor=''>Nom</label>
            <input
              type='text'
              placeholder='Nom'
              className={`${errors && errors['last_name'] ? 'error' : ''}`}
              {...register('last_name')}
            />
            <span className='error'>
              {`${
                errors && errors['last_name']?.message
                  ? errors['last_name']?.message
                  : ''
              }`}
            </span>
          </div>

          <div className='input-container'>
            <label htmlFor=''>Nom d’utilisateur</label>
            <input
              type='text'
              placeholder='Nom d’utilisateur'
              className={`${errors && errors['username'] ? 'error' : ''}`}
              {...register('username')}
            />
            <span className='error'>
              {`${
                errors && errors['username']?.message
                  ? errors['username']?.message
                  : ''
              }`}
            </span>
          </div>

          <div className='input-container'>
            <label htmlFor=''>Email</label>
            <input
              type='text'
              disabled={id}
              placeholder='Email'
              className={`${errors && errors['email'] ? 'error' : ''}`}
              {...register('email')}
            />
            <span className='error'>
              {`${
                errors && errors['email']?.message
                  ? errors['email']?.message
                  : ''
              }`}
            </span>
          </div>

          <select
            className={`${errors && errors['role'] ? 'error' : ''}`}
            {...register('role', {
              value: 'Technicien',
            })}
            defaultValue=''
            hidden
          >
            <option value='' disabled hidden>
              Select Role
            </option>

            <option value={'Technicien'}>Technicien</option>
          </select>
          <div className='input-container'>
            <label htmlFor=''>Numéro de téléphone</label>
            <input
              type='text'
              placeholder='telephone'
              className={`${errors && errors['phone'] ? 'error' : ''}`}
              {...register('phone')}
            />
            <span className='error'>
              {`${
                errors && errors['phone']?.message
                  ? errors['phone']?.message
                  : ''
              }`}
            </span>
          </div>
          <div className='input-container'>
            <label htmlFor=''>Sexe</label>
            <select
              name=''
              id=''
              className={`${errors && errors['sexe'] ? 'error' : ''}`}
              {...register('sexe')}
              defaultValue=''
            >
              <option value='' disabled hidden>
                select Sexe
              </option>
              <option value={'Femme'}>Femme</option>
              <option value={'Homme'}>Homme</option>
            </select>
            <span className='error'>
              {`${
                errors && errors['sexe']?.message ? errors['sexe']?.message : ''
              }`}
            </span>
          </div>

          <div className='input-container full'>
            <label htmlFor=''>Address</label>
            <textarea
              className={`${errors && errors['address'] ? 'error' : ''}`}
              {...register('address')}
              cols='30'
              rows='10'
            ></textarea>

            <span className='error'>
              {`${
                errors && errors['address']?.message
                  ? errors['address']?.message
                  : ''
              }`}
            </span>
          </div>

          <div className='input-container'>
            <label htmlFor=''>Nationalité</label>
            <input
              type='text'
              placeholder='Nationalité'
              className={`${errors && errors['Nationalité'] ? 'error' : ''}`}
              {...register('Nationalité')}
            />
            <span className='error'>
              {`${
                errors && errors['Nationalité']?.message
                  ? errors['Nationalité']?.message
                  : ''
              }`}
            </span>
          </div>
          {profile?.role === 'Admin' && profile?._id !== id && (
            <div className='input-container'>
              <label htmlFor=''>Status</label>
              <select
                className={`${errors && errors['status'] ? 'error' : ''}`}
                value={watch('status')}
                onChange={(e) => setValue('status', Boolean(e?.target?.value))}
              >
                <option value={true}>Active</option>
                <option value={false}>Inactive</option>
              </select>
              <span className='error'>
                {`${
                  errors && errors['status']?.message
                    ? errors['status']?.message
                    : ''
                }`}
              </span>
            </div>
          )}

          <div className='input-container'>
            <label htmlFor=''>CIN</label>
            <input
              type='text'
              placeholder='CIN'
              className={`${errors && errors['CIN'] ? 'error' : ''}`}
              {...register('CIN')}
            />
            <span className='error'>
              {`${
                errors && errors['CIN']?.message ? errors['CIN']?.message : ''
              }`}
            </span>
          </div>

          {/* <div className='flex full'>
            <div className='input-container'>
              <Upload
                onUpload={(url) => {
                  setValue('IdentityUrls', [
                    ...(watch('IdentityUrls') || []),
                    url,
                  ]);
                }}
                isImage={false}
              />
              <span className='error'>
                {`${
                  errors && errors['IdentityUrls']?.message
                    ? errors['IdentityUrls']?.message
                    : ''
                }`}
              </span>
            </div>
            <div className='input-container'>
              {(watch('IdentityUrls') || [])?.map((image, index) => (
                <div className='uploads' key={index}>
                  <Link to={image}>
                    <img src={'/images/pdf.png'} alt={`Image ${index + 1}`} />
                  </Link>
                  <button
                    type='button'
                    onClick={() => handleDeleteImage(index)}
                  >
                    <Icon name='close' />
                  </button>
                </div>
              ))}
            </div>
          </div> */}
        </form>
      </div>
      <button
        type='submit'
        form='user'
        className='add'
        style={{ margin: 'auto', marginTop: 24 }}
      >
        <Icon name={id ? 'edit' : 'add'} />
        {id ? 'Modifier' : 'Ajouter'}
      </button>
    </>
  );
}

export default FormUser;
