import { popup } from '../../components/Popup/Popup';
import { queryClient } from '../../layouts/ReactQueryLayout';
import { Axios } from './axios';

const getVisits = () => {
  return Axios.get(`/visits`).then(({ data }) => {
    let Visits = data?.sort(
      (a, b) => new Date(b?.dateVisite) - new Date(a?.dateVisite)
    );

    console.log({ Visits });

    return Visits;
  });
};

const getClient = (id) => {
  return Axios.get(`/clients/${id}`).then(({ data }) => {
    console.log({ Client: data });
    return data;
  });
};

const getClients = () => {
  return Axios.get(`/clients`).then(({ data }) => {
    console.log({ Clients: data });
    return data;
  });
};

const createClient = ({ data }) => {
  return Axios.post(`/clients`, data).then(({ data }) => {
    console.log({ Client: data });
    queryClient.invalidateQueries('clients');
    return data;
  });
};

const updateClient = ({ data }) => {
  return Axios.put(`/clients/${data?._id}`, data).then(({ data }) => {
    console.log({ Client: data });
    popup.show({
      title: 'Success',

      type: 'notification',
    });
    queryClient.invalidateQueries('clients');
    return data;
  });
};

const startCall = ({ data, id }) => {
  return Axios.post(`/clients/${id}/visits`, data).then(({ data }) => {
    console.log({ Visit: data });
    queryClient.invalidateQueries('visits');
    return data;
  });
};

const endCall = ({ data, id, visit_id }) => {
  return Axios.put(`/clients/${id}/visits/${visit_id}`, data).then(
    ({ data }) => {
      console.log({ Visit: data });
      queryClient.invalidateQueries('visits');
      return data;
    }
  );
};

export {
  getVisits,
  getClients,
  createClient,
  getClient,
  startCall,
  endCall,
  updateClient,
};
