import React, { Suspense, lazy } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import Login from '../screens/Authentification/Login';
import Register from '../screens/Authentification/Register';
import OTP from '../screens/Authentification/OTP/OTP';
import { useQuery } from 'react-query';
import { getProfile, getUser } from '../utils/api/users';
import Forgot from '../screens/Authentification/Forgot.jsx';
import Reset from '../screens/Authentification/Reset.jsx';
const RoleRouter = lazy(() => import('./RoleRouter.js'));

// import LoadingAnime from '../screens/LoadingAnime/LoadingAnime';

function RouterLayout() {
  const { data: token, isLoadingToken } = useQuery('token', () => {
    if (localStorage.getItem('token') && localStorage.getItem('user')) {
      return localStorage.getItem('token');
    } else {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
    }
    return;
  });

  const { data: user, error, isLoading } = useQuery('profile', getProfile);

  const authRouter = createBrowserRouter([
    {
      path: '/forgot',
      element: <Forgot />,
    },
    {
      path: '/reset',
      element: <Reset />,
    },
    {
      path: '/*',
      element: <Login />,
    },
  ]);

  // const otpRouter = createBrowserRouter([
  //   {
  //     path: '/*',
  //     element: <OTP />,
  //   },
  // ]);

  // if (isLoading || isLoadingToken || error) return <LoadingAnime />;
  if (!token) return <RouterProvider router={authRouter} />;
  // if (user && user?.email && !user?.otpVerified)
  //   return <RouterProvider router={otpRouter} />;

  return (
    <Suspense fallback={<div>{/* <LoadingAnime /> */}</div>}>
      <RoleRouter user={user} />
    </Suspense>
  );
}

export default RouterLayout;
