import React from 'react';
import './ReportingShow.css';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getReporting } from '../../../../utils/api/reporting';
import moment from 'moment';
import Icon from '../../../../components/Icon/Icon';
import { fileType } from '../../../../utils/fileType';

function ReportingShow() {
  const { id } = useParams();
  const { data: reporting } = useQuery(
    `reporting-${id}`,
    id ? () => getReporting(id) : () => {}
  );
  return (
    <div className='ReportingShow'>
      <section>
        <h3>Information Generales</h3>
        <div>
          <h4>Titre de rapport</h4>
          <p style={{ padding: '8px 14px' }}>{reporting?.title}</p>
          <span>Description</span>
          <p className='bg'>{reporting?.description}</p>
        </div>
        <h3>Auteur</h3>
        <h4>{reporting?.user_name}</h4>
        <h3>Période couverte</h3>
        <h4>
          De : {moment(reporting?.startDate)?.format('DD/MM/YYYY')} jusqu’à{' '}
          {moment(reporting?.endDate)?.format('DD/MM/YYYY')}
        </h4>
        <h3>Objectif</h3>
        <p className='bg'>{reporting?.objectif}</p>
        <h3>Sources de Données</h3>
        <ul>
          {reporting?.donnes?.map((item) => (
            <li key={item}>
              <button type='button'>
                <Icon name='check' />
              </button>
              <label htmlFor='base'>
                <label htmlFor='base'>{item} </label>
              </label>
            </li>
          ))}
        </ul>
        <h3>Commentaire</h3>
        <p className='bg'>{reporting?.commentaire}</p>{' '}
      </section>

      <div className='files'>
        <p></p>
        {reporting?.uploads?.map((file) => (
          <div>
            <img src={fileType(file?.name)} alt='' />
            <p>{file?.name}</p>
          </div>
        ))}
        {/* <Link>
       <button className='add' style={{ borderRadius: 10 }}>
          Enregistrer
        </button>
       </Link> */}
      </div>
    </div>
  );
}

export default ReportingShow;
