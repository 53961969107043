import { Toaster } from 'react-hot-toast';
import './App.css';
import AppRouter from './layouts/RouterLayout';
import Popup from './components/Popup/Popup';
import { getProfile } from './utils/api/users';
import { useQuery } from 'react-query';

function App() {
  const { data: profile } = useQuery('profile', getProfile);
  return (
    <>
      <Toaster position='top-right' />
      <AppRouter />
      <Popup />
    </>
  );
}

export default App;
