import { Outlet } from 'react-router-dom';
import AppLayout from '../AppLayout';
import Users from '../../screens/Users/Users';
import AddUser from '../../screens/Users/AddUser';
import UpdateUser from '../../screens/Users/UpdateUser';
import Dashboard from '../../screens/Dashboard/Dashboard';

const usersRouter = {
  path: '/',
  element: (
    <AppLayout>
      <Outlet />
    </AppLayout>
  ),
  children: [
    {
      path: '',
      element: <Users />,
    },
    {
      path: 'utilisateurs/:ajouter',
      element: <AddUser />,
    },
    {
      path: 'utilisateurs/:action/:id',
      element: <UpdateUser />,
    },
    {
      path: 'dashboard/:id',
      element: <Dashboard />,
    },
  ],
};

export { usersRouter };
