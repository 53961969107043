import { profile_local } from '../LocalStorage';
import { Axios } from './axios';

const getDashboard = () => {
  return Axios.get(`/dashboard`).then(({ data }) => {
    console.log({ dashboard: data });
    return data;
  });
};

const getDashboardTechno = (id) => {
  return Axios.get(`/dashboard/${id}`).then(({ data }) => {
    console.log({ dashboard: data });
    return data;
  });
};

export { getDashboard, getDashboardTechno };
