import React, { useEffect, useState, useRef } from 'react';
import io from 'socket.io-client';
import { useNavigate, useParams } from 'react-router-dom';
// import Peer from 'peerjs';
import { v4 as uuidv4 } from 'uuid';
import { profile_local, token_local } from '../utils/LocalStorage';
import { baseURL } from '../utils/api/axios';
import { queryClient } from '../layouts/ReactQueryLayout';

const useChat = () => {
  const [authToken, setAuthToken] = useState(token_local());
  const [listUsers, setListUsers] = useState([]);
  const [listUsersConnected, setListUsersConnected] = useState([]);
  const [chatId, setChatId] = useState(null);
  const [chatUser, setChatUser] = useState(null);
  const [listMessages, setListMessages] = useState([]);
  const [time, setTime] = useState(null);
  const [chatNotif, setChatNotif] = useState(0);
  const [scroll, setScroll] = useState(false);
  const { id } = useParams();
  //   const [videoChatId, setVideoChatId] = useState(null);
  //   const [isCalling, setIsCalling] = useState(false);
  //   const [isAccepting, setIsAccepting] = useState(false);
  //   const [showCall, setShowCall] = useState(false);
  const [showChat, setShowChat] = useState(false);
  //   const [haveCall, setHaveCall] = useState(false);
  //   const [stream, setStream] = useState(null);
  //   const [videoCall, setVideoCall] = useState(false);
  //   const [audioCall, setAudioCall] = useState(false);

  //   const myVideo = useRef(null);
  //   const clientVideo = useRef(null);
  const history = useNavigate();

  const socket = useRef(null);
  //   const peer = useRef(null);
  const audioNotif = useRef(new Audio('/message.mp3')); // Replace with your audio file path
  //   const callNotif = useRef(new Audio('path/to/audio/file')); // Replace with your call notification audio file

  useEffect(() => {
    socket.current = io.connect(baseURL?.replace('/api', '')); // Replace with your socket URL

    const initializeSocket = () => {
      if (!authToken && localStorage.getItem('token') !== null) {
        setAuthToken(localStorage.getItem('token'));
      }

      socket.current.disconnect();
      socket.current.io.opts.query = { token: authToken };
      console.log(socket.current.io.opts);
      socket.current.connect();

      socket.current.on('connect', () => {
        console.log('connect');
        socket.current.emit('list-rooms');
        socket.current.on('user-connected', async (data) => {
          // Handle user connected
        });

        socket.current.on('listUserConnected', async (data) => {
          console.log('listUserConnected', data);
          setListUsersConnected(data);
        });

        socket.current.on('listUsers', async (data) => {
          console.log('listUsers', data);
          setListUsers(data);
          //   if (!chatId) {
          //     setChatId(data[0]?._id);
          //   }

          //   let exist = data.find((u) => u._id === chatId);
          //   if (!exist && chatId !== 'all') {
          //     fetchUser(chatId);
          //   } else {
          //     setChatUser(exist);
          //   }
        });

        socket.current.on('listMessage', async (data) => {
          console.log('listMessage', data);
          setListMessages(data);
        });

        socket.current.on('error', (data) => console.log(data));

        socket.current.on('success', async (data) => {
          if (data.message && data.id_chat !== time) {
            setTime(data.id_chat);
            // data.from = profile_local()?._id;
            // socket.current.emit('list-rooms');
            // socket.current.emit('join-room', data.user_id);
            socket.current.emit('list-rooms');
            if (data.user_id === id)
              setListMessages((msgs) => [...(msgs || []), data]);
            document.querySelector('#form-message')?.reset();
          }
        });

        socket.current.on('createMessage', async (data) => {
          if (data.message && data.id_chat !== time) {
            setTime(data.id_chat);
            setChatNotif((prevNotif) => prevNotif + 1);
            setScroll(true);

            try {
              audioNotif.current.autoplay = true;

              audioNotif.current
                .play()
                .then()
                .catch((err) => console.log(err))
                .finally(() => {});
              setTimeout(() => queryClient.invalidateQueries('profile'), 1000);
            } catch (error) {}
            socket.current.emit('list-rooms');
            // if (data.Type === 'file' || data.type === 'file') {
            //   data.files = JSON.parse(data.message);
            //   data.message = '';
            // }

            // const index = listUsers.findIndex((u) => u._id === data.from);
            // if (index > -1) {
            //   setListUsers((prevListUsers) => {
            //     const newListUsers = [...prevListUsers];
            //     newListUsers[index].last_message = {
            //       ...newListUsers[index].last_message,
            //       Time: Date.now(),
            //       message: data.message || '',
            //       type: data.Type === 'file' ? 'file' : '',
            //     };
            //     return newListUsers;
            //   });
            // } else {
            //   socket.current.emit('list-rooms');
            //   socket.current.emit('join-room', data.from);
            // }

            if (data?.from === id)
              setListMessages((msgs) => [...(msgs || []), data]);
          }
        });

        // socket.current.on('call-user', async (data) => {
        //   if (!peer.current) {
        //     setVideoChatId(uuidv4());
        //     peer.current = new Peer(videoChatId, {
        //       /* peer config here */
        //     });
        //   }

        //   setShowChat(true);
        //   setChatUser(data.from);
        //   setShowCall(true);
        //   setHaveCall(true);
        //   history(`/chat/${data.from._id}`);
        //   setScroll(true);

        //   if (navigator.mediaDevices.getUserMedia) {
        //     try {
        //       const stream = await navigator.mediaDevices.getUserMedia({
        //         video: data.from.video,
        //         audio: data.from.audio,
        //       });
        //       setStream(stream);
        //       setIsCalling(true);
        //       setVideoCall(data.from.video);
        //       setAudioCall(data.from.audio);
        //       myVideo.current.srcObject = stream;
        //       myVideo.current.play();
        //       callNotif.current.loop = true;
        //       callNotif.current.play();
        //     } catch (error) {
        //       console.log('Something went wrong!');
        //     }
        //   }
        // });

        // socket.current.on('cancel-call', () => {
        //   stopCall();
        // });

        // socket.current.on('accept-call', async (data) => {
        //   callNotif.current.pause();
        //   setIsAccepting(true);

        //   try {
        //     peer.current.connect(data.video_chat_id);
        //     const call = peer.current.call(data.video_chat_id, stream);
        //     call.on('stream', (userVideoStream) => {
        //       clientVideo.current.srcObject = userVideoStream;
        //       clientVideo.current.play();
        //       setIsCalling(true);
        //     });
        //   } catch (error) {}
        // });
      });
    };

    initializeSocket();
    setListMessages([]);
    socket.current.emit('join-room', id);
    //

    return () => {
      socket.current.disconnect();
      //   if (stream) {
      //     stream.getTracks().forEach((track) => track.stop());
      //   }
    };
  }, [authToken, id]);

  useEffect(() => {
    if (listMessages?.length > 0) scroolToEnd();
  }, [listMessages]);

  const scroolToEnd = () => {
    try {
      document.querySelector('.BodyDescution .body').scrollTo({ top: 100000 });
    } catch (error) {}
  };

  const sendMessage = ({ message, type }) => {
    socket.current.emit('message', {
      user_id: id,
      type,
      message,
      from: profile_local()?._id,
      id_chat: uuidv4(),
    });
  };

  //   const stopCall = () => {
  //     if (stream) {
  //       stream.getTracks().forEach((track) => track.stop());
  //     }
  //     setHaveCall(false);
  //     setShowCall(false);
  //     setIsCalling(false);
  //   };

  return {
    listUsers,
    listMessages,
    chatUser,
    listUsersConnected,
    socket,
    sendMessage,
  };
};

export { useChat };
